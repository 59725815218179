import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", (e) => {
    e.preventDefault();

    const href = anchor.getAttribute("href");
    if (href) {
      const el = document.querySelector(href);

      if (el) {
        const headerOffset = window.innerWidth < 1024 ? 90 : 200;
        const elementPosition = el.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  });
});
